// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #78c829;
$secondary : #fda51f;
$accent    : #9C27B0;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

//ColorPalette
$gray: #4a4a4a;
$gray-light: #939393;
$gray-lighter: #f8f8f8;
$gray-disable: #f2f2f2;
$border-gray: rgba(82, 82, 82, 0.23);
$black: #000000;
$green: #78c829;
$green-dark: #5f7e28;
$blue: #3d5793;
$white: #ffffff;
$orange: #fda51f;
$red: #e84c1b;

//Font
$principal: 'Nunito', sans-serif;

@mixin font($font-size, $font-weight, $font-color) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: initial;
	color: $font-color;
	font-family: $principal;
}
